






































































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapGetters, mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { formatISO } from 'date-fns'

export default defineComponent({
  name: 'ReminderForm',
  components: {
    DateField: () => import('@/components/base/DateField.vue'),
    TimeField: () => import('@/components/base/TimeField.vue'),
    ConfirmChanges: () => import('./ConfirmChanges.vue'),
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    defaultReminderType: {
      type: String,
      required: false,
      default: 'Téléphone',
    },
    defaultContactType: {
      type: String,
      required: false,
      default: 'Stagiaire',
    },
  },
  data () {
    return {
      dialogConfirmChanges: false,
      isSubmiting: false,
      reminder: {},
    }
  },
  created () {
    this.initReminder()
  },
  computed: {
    ...mapGetters('filterList', {
      filters: 'getFiltersParams',
    }),
    ...mapState('traineeList', {
      totalNeedTrainings: 'totalItems',
    }),
    getMessage (): string {
      return this.$t('dialog.message_confirmation_mass_send_reminders', {
        number: this.totalNeedTrainings,
      }) as string
    },
  },
  methods: {
    ...mapActions('reminderForm', {
      massSave: 'massSave',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    openDialogConfirmChanges () {
      this.$emit('close')
      this.dialogConfirmChanges = true
    },
    getValidationObserver () {
      return this.$refs.observer as InstanceType<typeof ValidationObserver>
    },
    async submit () {
      this.isSubmiting = true
      try {
        await this.massSave({ reminder: this.reminder, filters: this.filters })
        this.close()
        await this.sendSuccessMessage('mass_sending_reminders.sended')
      } finally {
        this.isSubmiting = false
      }
    },
    clear () {
      this.getValidationObserver().reset()
      this.dialogConfirmChanges = false
    },
    close () {
      this.clear()
      this.$emit('close')
    },
    initReminder () {
      this.reminder = {
        reminderAt: formatISO(new Date()),
        reminderType: this.defaultReminderType,
        commentary: '',
        contactType: this.defaultContactType,
      }
    },
  },
})
